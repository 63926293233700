import { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header';
import Mes from './components/Mes';
import SideBar from './components/SideBar';
import Dia from './components/Dia';

import Semana from './components/Semana';
import Footer from './components/Footer';
import Loading from './components/Loading';
import Weather from './components/Weather';
import MesAtual from './services/MesAtual';
import { CSSTransition, SwitchTransition  } from 'react-transition-group';
//32 conexao DI
//33 conexao SL
const padrao = {
  "id": "32",
  "periodo": MesAtual(),
  "senha": ""
}

function App() {

  const [data, setData] = useState([])
  const [dadosRequisicao, setDadosRequisicao] = useState(padrao)
  const [mostrarMes, setMostrarMes] = useState(true);
  const [VisuUnica, setVisuUnica] = useState(false);
  const [intervaloAtivo, setIntervaloAtivo] = useState(false);


  useEffect(() => {
    getData()

    if (dadosRequisicao.periodo === padrao.periodo) {
      const intervalId = setInterval(() => {
        getData(); // API sendo chamada a cada minuto
      }, 60000);

      // Função de limpeza para quando o componente é desmontado
      return () => clearInterval(intervalId);
    }
  }, [dadosRequisicao])

  const handleDataFromHeader = (headerData) => {
    setData(headerData);
    setDadosRequisicao(headerData);
  };

  const handleCheckboxChange = () => {
    setIntervaloAtivo(prev => !prev);
  };

  const renderMesDiaSemana = () => (
    <>
      <Mes data={data[0]?.Mes} />
      <div className='flex mt-5'>
        <Dia data={data[0]?.Dia} />
        <Semana data={data[0]?.Semana} />
      </div>
    </>
  );

  const renderDiaSemana = () => (
    <>
      <div className='flex mt-5'>
        <Dia data={data[0]?.Dia} />
        <Semana data={data[0]?.Semana} />
      </div>
    </>
  );

  const renderToggleButton = () => (
    <>
    <button
      className='bg-white text-emerald-800 p-2 rounded mt-2 ml-2'
      onClick={() => setMostrarMes(!mostrarMes)}
    >
      {mostrarMes ? 'Mostrar Dia e Semana' : 'Mostrar Mês'}
    </button>
    <label className='bg-white text-emerald-800 p-2 rounded mt-2 ml-2'>
        <input
          type="checkbox"
          checked={intervaloAtivo}
          onChange={handleCheckboxChange} />
         <span className="text-xm mt-2 ml-2" > Alterar automaticamente</span>
      </label>
    </>
  );

  useEffect(() => {
    let intervalo;
    if (intervaloAtivo && VisuUnica) {
      intervalo = setInterval(() => {
        setMostrarMes(prev => !prev);
      }, 30000); // Alterna a cada 30 segundos
    }
    return () => clearInterval(intervalo);
  }, [VisuUnica, intervaloAtivo]);

  async function getData() {
    try {
      let url = 'https://web.priorizatec.com.br';
      // let url = 'http://localhost:8000';
      const promisse = await fetch(url + `/api/conexao-ativa/dash?empresa_id=${dadosRequisicao.id}&periodo=${dadosRequisicao.periodo}`)
      const response = await promisse.json()
      setData(response)
    } catch (error) {
      console.error('Error fetching data', error)
    }
  }

  if (data.length === 0) {
    return <Loading />
  }

  // console.log(data)

  return (
    <>
      <Header data={data[0]?.SideBar} requisicao={handleDataFromHeader} onToggleVisuUnica={setVisuUnica}  />
      <div className='flex justify-between bg-gradient-to-b from-emerald-800 to-emerald-400 w-full'>
        <div className='bg-gradient-to-b from-emerald-800 to-emerald-400 pb-5'>
        <SwitchTransition>
            <CSSTransition
              key={VisuUnica ? 'visu-unica' : 'normal'}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              {VisuUnica ? (
                <>
                  {renderToggleButton()}
                  <SwitchTransition>
                    <CSSTransition
                      key={mostrarMes ? 'mes' : 'dia-semana'}
                      timeout={300}
                      classNames="fade"
                      unmountOnExit
                    >
                      {mostrarMes ? <Mes data={data[0]?.Mes} /> :renderDiaSemana()}
                    </CSSTransition>
                  </SwitchTransition>
                </>
              ) : (
                renderMesDiaSemana()
              )}
            </CSSTransition>
          </SwitchTransition>
          {/* <Weather /> */}
        </div>

        <SideBar data={data[0]?.SideBar} />
      </div>
      
       <Footer data={data[0]?.SideBar} />
    </>
  );
}

export default App;
