import { useState } from "react"
import GraficoRosca from "../graficos/GraficoRosca"
import GraficoRoscaVendedor from "../graficos/GraficoRoscaVendedor"
import Velocimetro from "../graficos/Velocimetro"
import ComparacaoAnos from "./ComparacaoAnos"
import Desempenho from "./Desempenho"
import MesAnterior from "./MesAnterior"
import Niveis from "./Niveis"

const Mes = (data) => {

    const { Vendedores } = data.data

    const [display, setDisplay] = useState("hidden")

    function HandleClick() {
        if (display === "hidden") {
            setDisplay("grid")
        } else {
            setDisplay("hidden")
        }
    }

    return (
        <div className="overflow-auto pt-5">
            <div className="flex justify-center">
                <h2 className="py-4 px-20 bg-emerald-950 shadow-lg shadow-emerald-950 rounded-3xl text-zinc-100 text-3xl text-center">MÊS</h2>
            </div>

            <div className="flex gap-3 px-2 max-width">
                <div>
                    <div className="flex flex-col items-center py-5 gap-1">
                        <h2 className="py-2 px-5 bg-emerald-900 shadow-lg shadow-emerald-900/50 rounded-xl text-zinc-100 text-center w-72">Comparativo de performance anual</h2>
                        <div className="bg-gradient-to-b from-emerald-900 to-emerald-600 shadow-2xl shadow-black border-2 border-emerald-400 rounded-lg">
                            <ComparacaoAnos dados={data.data} />
                        </div>
                    </div>

                    <div className="flex flex-col items-center pt-3">
                        <h2 className="py-2 px-5 bg-emerald-900 shadow-lg shadow-emerald-900/50 rounded-xl text-zinc-100 text-center w-36 cursor-pointer" onClick={HandleClick}>
                            Vendedor
                        </h2>
                        {display === "hidden" ? <div className="mt-2">Clique no botão para visualizar os dados</div> : ''}
                        <div className={`${display} grid-cols-5 my-1 gap-2 bg-gradient-to-b from-emerald-900 to-emerald-600 shadow-2xl shadow-black border-2 border-emerald-400 rounded-lg`}>
                            {Vendedores.map((vendedor) => {
                                return (
                                    <GraficoRoscaVendedor key={vendedor.Nome} dados={vendedor} />
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-12 mt-5">
                    <div className="flex gap-2">
                        <div className="flex flex-col items-center gap-1">
                            <h2 className="py-2 px-5 bg-emerald-900 shadow-lg shadow-emerald-900/50 rounded-xl text-zinc-100 text-center w-36">Nivel {data.data.MesAtual.Nivel}</h2>
                            <div className="flex bg-gradient-to-b from-emerald-900 to-emerald-600 shadow-2xl shadow-black border-2 border-emerald-400 rounded-lg">
                                <GraficoRosca Titulo="Foi" dados={data.data} />
                                <GraficoRosca Titulo="Falta" dados={data.data} />
                            </div>
                        </div>

                        <div className="flex flex-col items-center gap-1">
                            <h2 className="py-2 px-5 bg-emerald-900 shadow-lg shadow-emerald-900/50 rounded-xl text-zinc-100 text-center w-36">Proximos niveis</h2>
                            <Niveis dados={data.data} />
                        </div>
                    </div>

                    <div className="flex gap-2">
                        <div className="flex flex-col items-center gap-1">
                            <h2 className="py-2 px-5 bg-emerald-900 shadow-lg shadow-emerald-900/50 rounded-xl text-zinc-100 text-center w-36">Contador Mês</h2>
                            <Velocimetro tipo="Mês" width="width" dados={data.data} />
                        </div>

                        <div className="flex flex-col items-center gap-1">
                            <h2 className="py-2 px-5 bg-emerald-900 shadow-lg shadow-emerald-900/50 rounded-xl text-zinc-100 text-center w-36">Desempenho</h2>
                            <Desempenho dados={data} />
                        </div>
                    </div>

                    <div>
                        <MesAnterior dados={data.data} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Mes