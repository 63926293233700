export default function MesAtual() {
    const data = new Date()
    const month = (data.getMonth() + 1).toString().padStart(2, '0')
    const year = data.getFullYear()
    return `${month}-${year}`
}

export function DiaAtual() {
    const data = new Date()
    const date = data.getDate()    
    return date
}