import { Chart } from "react-google-charts";

const GraficoRosca = ({ Titulo, dados }) => {

    const { VendasFeitas, VendasFaltando } = dados.MesAtual
    let data = []

    const options = {
        title: "",
        legend: "none",
        pieHole: 0.61,
        pieSliceText: "none",
        backgroundColor: {
            fill: "transparent",
        },
        colors: ['#10b981','#022c22']
    };

    const chartStyle = {
        borderRadius: "8px", 
        overflow: "hidden",
    };

    if( Titulo === "Foi" ){
        data = [
            ["jaFoi", "aindaFalta"],
            ["Vendas Feitas", VendasFeitas],
            ["Vendas Faltando", VendasFaltando], 
        ];
    } else {
        data = [
            ["jaFoi", "aindaFalta"],
            ["Vendas Faltando", VendasFaltando], 
            ["Vendas Feitas", VendasFeitas],
        ];
    }

    return(
        <div className="flex items-center relative rounded-lg">
            <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width="256px"
                height="256px"
                style={chartStyle}
            />

            <div className="flex gap-1 absolute left-[107px] items-baseline ">
                {Titulo === "Foi" ?
                    <div className="flex items-center w-[56px]">
                        <p className="text-emerald-500 text-5xl">
                            {VendasFeitas}
                        </p>
                        <p className="text-emerald-950">
                            {VendasFaltando}
                        </p> 
                    </div>
                : <div className="flex items-center w-[56px]">
                    <p className="text-emerald-950 text-5xl">
                        {VendasFaltando}
                    </p>
                    <p className="text-emerald-500">
                        {VendasFeitas}
                    </p>
                  </div> 
                }
            </div>

            <div className="py-2 px-5 bg-emerald-900 shadow-lg shadow-emerald-900/50 absolute left-24 rounded-xl bottom-2 z-0">
                <p className="text-zinc-100">{Titulo}</p>
            </div>
        </div>
    )
}

export default GraficoRosca