const SideMesAnterior = ({ data }) => {
    return(
        <div className="flex flex-col justify-between gap-10 bg-gradient-to-b from-emerald-800 to-emerald-600 shadow-md shadow-emerald-500 p-2 w-full rounded-lg">
            <div className="text-center pt-7">
                <p>Resumo mês anterior:</p>
                <span>{data.Mes}</span>
            </div>
            <div className="flex flex-col gap-3 text-center">
                <div className="flex flex-col items-center">
                    <span>Mês nível {data.Nivel}: </span>
                    <span>{data.ValorNivel}</span>
                </div>
                <div className="flex flex-col items-center">
                    <span>Semana: </span>
                    <span>{data.Semana}</span>
                </div>
                <div className="flex flex-col items-center">
                    <span>Dia: </span>
                    <span>{data.Dia}</span>
                </div>
            </div>
            <div className="text-center text-lg">
                <p>TOTAL: {data.Total}</p>
            </div>
        </div>
    )
}

export default SideMesAnterior