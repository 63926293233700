import { useState } from "react"
import NivelMesAnterior from "../NivelMesAnterior"
import GraficoBarras from "../graficos/GraficoBarras"
import GraficoRoscaVendedor from "../graficos/GraficoRoscaVendedor"
import Velocimetro from "../graficos/Velocimetro"

const Dia = ({ data }) => {

    const Vendedores = data.Vendedores

    const [display, setDisplay] = useState("hidden")

    function HandleClick() {
        if(display === "hidden"){
            setDisplay("grid")
        } else{
            setDisplay("hidden")
        }
    }

    return(
        <div className="pl-2 pr-0 ">
            <div className="flex justify-center py-5">
                <h2 className="py-4 px-20 bg-emerald-950 shadow-lg shadow-emerald-950 rounded-3xl text-zinc-100 text-3xl text-center">DIA</h2>
            </div>

            <div className="flex gap-1 items-center">
                <Velocimetro dados={data} width="menor" />
                <div className="bg-gradient-to-b from-emerald-950 to-emerald-700 border-2 border-emerald-400 shadow-2xl shadow-black rounded-lg">
                    <GraficoBarras dados={data} />
                </div>
            </div>

            <div className="flex flex-col items-center py-4"> 
                <h2 className="py-2 px-5 bg-emerald-900 shadow-lg shadow-emerald-900/50 rounded-xl text-zinc-100 text-center w-36 cursor-pointer" onClick={HandleClick}>
                    Vendedor
                </h2>
                { display==="hidden" ? <div className="mt-2">Clique no botão para visualizar os dados</div> : '' }
                <div className={`${display} grid-cols-5 my-1 gap-2 bg-gradient-to-b from-emerald-900 to-emerald-600 shadow-2xl shadow-black border-2 border-emerald-400 rounded-lg`}>
                    {Vendedores.map((vendedor) => {
                        return(
                            <GraficoRoscaVendedor key={vendedor.Nome} dados={vendedor} />
                        )
                    })}
                </div>
            </div>

            <NivelMesAnterior dados={data} />
        </div>
    )
}

export default Dia