import SideMesAnterior from "./SideMesAnterior"
import SideNum from "./SideNum"

const SideBar = ({data}) => {

    const { Atual, Anterior } = data

    return(
        <div className="bg-black text-zinc-100 w-44 flex flex-col gap-7 items-center px-2 pt-5 ">
            <SideNum titulo="Mês" num={Atual.Mes} />
            <SideNum titulo="Semana" num={Atual.Semana} />
            <SideNum titulo="Dia" num={Atual.Dia} />
            <SideNum titulo="Total" num={Atual.Total} />
            <SideMesAnterior data={Anterior} />
        </div>
    )
}

export default SideBar