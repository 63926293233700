import './MesAnterior.css'

const MesAnterior = ({ dados }) => {

    const { Mes, Nivel, ValorPago } = dados.MesAnterior

    return(
        <div className="grid grid-cols-3 bg-emerald-950 border-2 border-emerald-400 shadow-2xl shadow-black 
            justify-items-center items-center py-3 text-zinc-100 rounded-lg mes-width">
            <div className='text-center'>
                <p>Resumo do mês anterior:</p>
                <span>{Mes}</span>
            </div>

            <span className='text-2xl'>
                Nível {Nivel}
            </span>

            <div className='text-center'>
                <p>Valor pago:</p>
                <span>{ValorPago}</span>
            </div>
        </div>
    )
}

export default MesAnterior