import { Chart } from "react-google-charts";

const ComparacaoAnos = ({ dados }) => {
  const ComparacaoAnual = dados.ComparacaoAnual;

  const options = {
    pointSize: 5,
    annotations: {
      textStyle: {
        fontName: "Times-Roman",
        fontSize: 18,
      },
    },
    title: "",
    curveType: "function",
    chartArea: { width: "75%" },
    legend: { position: "bottom", textStyle: { color: "white" } },
    backgroundColor: {
      fill: "transparent",
    },
    hAxis: {
      titleTextStyle: { color: "white" },
      textStyle: { color: "white" },
    },
    vAxis: {
      titleTextStyle: { color: "white" },
      textStyle: { color: "white" },
      gridlines: { color: "#064e3b" },
      minorGridlines: { color: "#064e3b" },
      scaleType: "log",
    },
    titleTextStyle: { color: "white" },
    colors: ["#ffffff", "#40f700"],
  };

  const chartStyle = {
    borderRadius: "8px",
    overflow: "hidden",
  };

  return (
    <Chart
      chartType="LineChart"
      width="900px"
      height="453px"
      data={ComparacaoAnual}
      options={options}
      style={chartStyle}
    />
  );
};

export default ComparacaoAnos;
