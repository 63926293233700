import gif from '../../assets/white.gif'

const Loading = () => {
    return (
        <>
            <div className="flex justify-center items-center bg-gradient-to-b from-emerald-800 to-emerald-400 h-screen">
                <img className='w-24' src={gif} />
            </div>
        </>
    )
}

export default Loading