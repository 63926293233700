import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, useRef } from "react";

const Desempenho = ({ dados }) => {
    const { MesAtual, ComparacaoEmPorcentagem } = dados.data.MesAtual;
    const [arrow, setArrow] = useState(faArrowDown);

    // Using useRef to store mutable values
    const colorRef = useRef("");
    const textColorRef = useRef("");

    useEffect(() => {
        if (parseFloat(ComparacaoEmPorcentagem) > 0) {
            setArrow(faArrowUp);
            colorRef.current = "#34d399";
            textColorRef.current = "text-emerald-500";
        } else {
            setArrow(faArrowDown);
            colorRef.current = "#dc2626";
            textColorRef.current = "text-red-600";
        }
    }, [ComparacaoEmPorcentagem]);

    if (parseFloat(ComparacaoEmPorcentagem) > 0) {
        colorRef.current = "#34d399";
        textColorRef.current = "text-emerald-500";
    } else {
        colorRef.current = "#dc2626";
        textColorRef.current = "text-red-600";
    }

    return (
        <div className="h-64 w-64 bg-gradient-to-b from-emerald-950 to-emerald-700 border-2 border-emerald-400 shadow-2xl shadow-black flex flex-col justify-between py-2 items-center rounded-lg">
            <div className="flex gap-5 items-center">
                <span className="text-zinc-100">{MesAtual} {dados.data.ComparacaoAnual[0][1]}</span>
                <FontAwesomeIcon icon={arrow} style={{ color: colorRef.current }} className=" text-4xl" />
            </div>
            <div className="text-zinc-100 text-center">
                <span className={`text-6xl ${textColorRef.current}`}>{ComparacaoEmPorcentagem}%</span>
            </div>
            <span className="text-zinc-100">Comparação {MesAtual} {dados.data.ComparacaoAnual[0][3]}</span>
        </div>
    );
};

export default Desempenho;
