import GaugeComponent from 'react-gauge-component'
import './Velocimetro.css'

const Velocimetro = ({ dados, width }) => {

    const { Tipo, NivelAtual, NivelMaximo, Niveis, Pagando, Alcancavel, CompletarAtual } = dados.Contador

    let arc = []
    for (let i = 0; i < NivelMaximo - NivelMaximo / 2; i++) {
        if (arc.length === 0) {
            arc.push({ limit: 2 })
        }
        else if (arc[i - 1].limit >= NivelMaximo - 2) {
            break
        }
        else {
            arc.push({ limit: arc[i - 1].limit + 2 })
        }
    }

    return (
        <div className={`bg-gradient-to-b from-emerald-800 to-emerald-500 border-2 border-emerald-400 shadow-2xl shadow-black flex items-center text-black text-center ${width} rounded-lg`}>
            <GaugeComponent
                value={NivelAtual}
                maxValue={NivelMaximo}
                type="radial"
                labels={{
                    valueLabel: {
                        formatTextValue: value => 'N' + value
                    },
                    tickLabels: {
                        type: "inner",
                        ticks: Niveis,
                        valueConfig: { formatTextValue: value => value + 'N' },
                    }
                }}
                arc={{
                    colorArray: ['#124002', '#40f700'],
                    subArcs: arc,
                    padding: 0.02,
                    width: 0.3
                }}
                pointer={{
                    color: '#f4f4f5',
                    elastic: true,
                    animationDelay: 0
                }}
            />

            <div className='flex flex-col justify-between text-[#0d1809] h-full pr-4'>
                {CompletarAtual ?
                    <div className='text-lg text-[#40f700]'>
                        <span>Falta comple. nivel</span>
                        <p className='text-xl'>{CompletarAtual}</p>
                    </div> :
                    <div></div>
                }

                <div className='text-xl px-6'>
                    <span>Pagando {Tipo}</span>
                    <p className='text-2xl font-semibold'>{Pagando}</p>
                </div>

                <div>
                    <span>Alcançavel</span>
                    <p className='text-lg'>{Alcancavel}</p>
                </div>
            </div>
        </div>
    )
}

export default Velocimetro