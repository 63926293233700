import { useEffect, useState } from "react"

const Footer = ({ data }) => {

    let innerWidth = window.innerWidth

    const [ width, setWidth ] = useState("w-[1819px]")

    useEffect(() => {
        if ( innerWidth > 1847 ){
            setWidth("w-full")
        }
    }, [innerWidth])

    const { AnoAtual, MesAtual, ValorAtual, MesPassado, ValorMesPassado, AnoPassado, ValorAnoPassado } = data.Footer
    return(
        <footer className={`bg-black text-zinc-100 p-2 flex justify-center gap-10 items-center ${width}`}>
            <div>
                <span>{MesPassado} {AnoAtual}= {ValorMesPassado}</span>
            </div>

            <p className="text-red-500">TICKET MÉDIO</p>

            <div className="bg-gradient-to-b from-emerald-800 to-emerald-600 shadow-md shadow-emerald-500 rounded-lg py-4 px-7">
                <span>{MesAtual} {AnoAtual}= {ValorAtual}</span>
            </div>

            <p className="text-red-500">TICKET MÉDIO</p>

            <div>
                <span>{MesAtual} {AnoPassado}= {ValorAnoPassado}</span>
            </div>
        </footer>
    )
}

export default Footer