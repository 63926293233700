import { Chart } from "react-google-charts";
import './Barras.css'

const GraficoBarras = ({ dados }) => {
    const data = dados.ResumoBarras;
    const valorTotal = dados.ValorTotalBarras
    const valores = dados.ValorPagoNiveis
      
    const options = {
        title: "Resumo Mês",
        titleTextStyle: { color: 'white' }, 
        chartArea: { width: "75%" },
        hAxis: {
          title: `Total ${valorTotal}`,
          minValue: 0,
          titleTextStyle: { color: 'white' }, 
          textStyle: { color: 'white' }
        },
        vAxis: {
          titleTextStyle: { color: 'white' }, 
          textStyle: { color: 'white' }
        },
        backgroundColor: {
            fill: "transparent"
        },
        colors: ['#34d399'],
        legend: { position: "none" }
    };

    const chartStyle = {
        borderRadius: "8px", 
        overflow: "hidden",
    };

    return(
        <div className="relative">
            <Chart
                chartType="BarChart"
                width="380px"
                height="252px"
                data={data}
                options={options}
                style={chartStyle}
            />

            <div className="graph">
                {valores.map((valor, index) => {
                    return (
                        <span key={index}>{valor}</span>
                    )
                })}
            </div>
        </div>
    )
}

export default GraficoBarras