const NivelMesAnterior = ({ dados }) => {

    const { Mes, Nivel_Z, Nivel_1, Nivel_2, Nivel_3, Nivel_4 ,Nivel_5, ValorPago } = dados.ResumoAnterior

    return(
        <div className="flex bg-emerald-950 shadow-2xl shadow-black justify-around items-center p-3 text-zinc-100 rounded-lg w-full">
            <div className='text-center text-xl'>
                <p>Resumo do mês anterior:</p>
                <span>{Mes}</span>
            </div>

            <div className="flex gap-5">
                <div className="flex flex-col text-red-600">
                    <span className='text-xl'>Nível Z - {Nivel_Z}</span>
                    <span className='text-xl'>Nível 1 - {Nivel_1}</span>
                </div>
                <div className="flex flex-col text-orange-500">
                    <span className='text-xl'>Nível 2 - {Nivel_2}</span>
                    <span className='text-xl'>Nível 3 - {Nivel_3}</span>
                </div>
                <div className="flex flex-col text-emerald-500">
                    <span className='text-xl'>Nível 4 - {Nivel_4}</span>
                    <span className='text-xl'>Nível 5 - {Nivel_5}</span>
                </div>
            </div>

            <div className='text-center text-xl'>
                <p>Valor pago:</p>
                <span>{ValorPago}</span>
            </div>
        </div>
    )
}

export default NivelMesAnterior