import { Chart } from "react-google-charts";

const GraficoRoscaVendedor = ({ dados }) => {

    const { Nome, VendasFeitas, VendasFaltando } = dados

    const options = {
        title: "",
        legend: "none",
        pieHole: 0.61,
        pieSliceText: "none",
        backgroundColor: {
            fill: "transparent"
        },
        colors: ['#10b981','#022c22'],
        chartArea: {width:"90%", height:"90%"}
    };

    const chartStyle = {
        borderRadius: "8px", 
        overflow: "hidden",
    };

    const data = [
        ["jaFoi", "aindaFalta"],
        ["Vendas Feitas", VendasFeitas],
        ["Vendas Faltando", VendasFaltando], 
    ];
    return(
        <div>
            <div className="flex items-center justify-center relative rounded-lg mt-1">
                <Chart
                    chartType="PieChart"
                    data={data}
                    options={options}
                    width="140px"
                    height="140px"
                    style={chartStyle}
                />

                <div className="flex gap-1 absolute items-baseline left-16">
                    <p className="text-emerald-500 text-3xl">
                        {VendasFeitas}
                    </p>
                    <p className="text-emerald-950">
                        {VendasFaltando}
                    </p>
                </div>
                
            </div>

            <div className="py-1 px-5 mb-2 bg-emerald-900 shadow-lg shadow-emerald-900/50 text-sm rounded-xl z-0 text-center">
                <p className="text-zinc-100">{Nome}</p>
            </div>
        </div>
    )
}

export default GraficoRoscaVendedor