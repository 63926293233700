const Niveis = ({ dados }) => {

    const {Nivel, FaltaParaCompletar} = dados.MesAtual

    return(
        <div className="h-64 w-64 bg-gradient-to-b from-emerald-800 to-emerald-500 border-2 border-emerald-400 shadow-2xl shadow-black 
            flex flex-col justify-between py-2 items-center rounded-lg">
            <span className="text-zinc-200">Entre os niveis {Nivel} e {Nivel + 1}</span>
            <div className="text-zinc-100 text-lg text-center">
                <p className=" ">Falta Completar Nivel</p>
                <span>R$ {FaltaParaCompletar}</span>
            </div>
            <span className="text-zinc-200">Decrescente</span>
        </div>
    )
}

export default Niveis